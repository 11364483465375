import { Link } from "gatsby";
import React from "react";
import Asset from "./asset";
import PortableText from "@sanity/block-content-to-react";
import serializers from "./serializers";
import styles from "./asof-grid.module.scss";
import theme from "../styles/theme.module.scss";

function Past(props) {
  return (
    <div className={`${styles.root} ${theme.gdcont} ${theme.gmcont}  ${theme.mT1}`}>
      {props.header && (
        <>
          <h2
            className={`${styles.header} ${theme.h3_founders} ${theme.gmmain} ${theme.mT7} ${theme.mT7} ${theme.mB7}`}
          >
            More
          </h2>
        </>
      )}

      <ul className={`${styles.list} ${theme.gdfull} ${theme.gmmain} ${theme.fdcont}`}>
        {props.nodes &&
          props.nodes.map((node, index) => (
            <li className={`${theme.fd5} ${theme.mB3}`} key={index}>
              <Link to={`/asof/${node.slug.current}`}>
                <div key={index} className={`${styles.entry} `}>
                  <Asset
                    playMode="hover"
                    asset={node.previewVideo[0]}
                    maxWidth="800"
                    className={`${theme.border}`}
                  />
                </div>
                <h2
                  className={`${theme.borderBottom}  ${theme.h5_founders} ${theme.mT11} ${theme.pB12} ${theme.mB12}`}
                >
                  {node.title}
                </h2>

                <p className={`${theme.portableText} ${theme.mB10}`}>{node.previewCopy}</p>

                <span className={` ${theme.h5_founders} ${theme.text_link} `}>Watch the video</span>
              </Link>
            </li>
          ))}
      </ul>
    </div>
  );
}

export default Past;
