import React from "react";
import styles from "./asof-single.module.scss";
import theme from "../styles/theme.module.scss";
import RowText from "./asof-single-row-text";
import RowImage from "./asof-single-row-image";
import RowProjectLink from "./asof-single-row-projectlink";

function AsofRows(props) {
  return (
    <div className={`${styles.rows}`}>
      {props.rows &&
        props.rows.map((row, key) => {
          switch (row._type) {
            case "asofText":
              return <RowText type={row._type} row={row} />;

            case "asofImage":
              return <RowImage type={row._type} row={row} />;

            case "asofProjectLink":
              return <RowProjectLink type={row._type} row={row} />;
          }
        })}
    </div>
  );
}

export default AsofRows;
