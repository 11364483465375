import React from "react";
import PortableText from "@sanity/block-content-to-react";
import serializers from "./serializers";
import theme from "../styles/theme.module.scss";

function ProjectRow(props) {
  const row = props.row;
  const gds = `gds${row.grid.gridStart}`;
  const gde = `gde${row.grid.gridEnd}`;
  const gms = row.gridMobile ? `gms${row.gridMobile.gridStart}` : `gms1`;
  const gme = row.gridMobile ? `gme${row.gridMobile.gridEnd}` : `gme4`;
  return (
    <div className={`${theme.gdcont} ${theme.gmcont} ${theme.mB3}`}>
      <div className={[theme[gds], theme[gde], theme[gms], theme[gme], theme.gmfull].join(" ")}>
        <h2 className={`${theme.h5_founders} ${theme.mB8} ${theme.center}`}>{row.title}</h2>
        <PortableText
          className={`${theme.portableText} ${theme.fd4} ${theme.mB10}`}
          renderContainerOnSingleChild={true}
          blocks={row._rawCopyPortable}
          serializers={serializers}
        />
        {row.ctaText && (
          <>
            <div className={[theme.mT8, theme.h5_founders, theme.center].join(" ")}>
              <a className={`${theme.text_link}`} href={row.ctaLink}>
                {row.ctaText}
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ProjectRow;
