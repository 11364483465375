import React from "react";
import Link from "./link";
import Asset from "./asset";
import theme from "../styles/theme.module.scss";

function ProjectRow(props) {
  const row = props.row;
  const gds = `gds${row.grid.gridStart}`;
  const gde = `gde${row.grid.gridEnd}`;
  const gms = row.gridMobile ? `gms${row.gridMobile.gridStart}` : `gms1`;
  const gme = row.gridMobile ? `gme${row.gridMobile.gridEnd}` : `gme4`;
  return (
    <div className={`${theme.gdcont} ${theme.gmcont} ${theme.mB1}`}>
      <div className={[theme[gds], theme[gde], theme[gms], theme[gme], theme.gmfull].join(" ")}>
        <h2 className={`${theme.h5_founders} ${theme.mB11}`}>{row.title}</h2>
        <Link to={`/work/${row.projectLink.slug.current}`}>
          <Asset asset={row.projectLink.thumbnailAsset[0]} width={1000} height={600} />
          <div className={`${theme.project_meta} ${theme.mT11}`}>
            <h3
              className={[theme.h5_founders, theme.mB12, theme.pB12, theme.borderBottom].join(" ")}
            >
              <Link to={`/work/${row.projectLink.slug.current}`}>{row.projectLink.title}</Link>
            </h3>
            <ul className={theme.project_cat}>
              {row.projectLink.tags && (
                <>
                  {row.projectLink.tags.map(function (cat, index) {
                    return <li key={index}>{cat.value}</li>;
                  })}
                </>
              )}
              {row.projectLink.tags.length == 0 && (
                <>
                  {row.projectLink.categories.map(function (cat, index) {
                    return <li key={index}>{cat.title}</li>;
                  })}
                </>
              )}
            </ul>
          </div>
        </Link>
      </div>
    </div>
  );
}

export default ProjectRow;
