import { Link } from "gatsby";
import React from "react";
import AssetVimeo from "./assetVimeo";
import Asset from "./asset";
import styles from "./asof-single.module.scss";
import theme from "../styles/theme.module.scss";

import PortableText from "@sanity/block-content-to-react";
import serializers from "./serializers";

function ProjectSingle(props) {
  const nextPrevItems = [props.prev, props.next];
  return (
    <div className={`${styles.root} ${theme.gdcont} ${theme.gmcont}`}>
      <div
        className={`${styles.wrapper} ${theme.gds2} ${theme.gde11} ${theme.gmmain} ${theme.mT6}`}
      >
        <div
          className={`${styles.meta} ${theme.fdcont} ${theme.fmcont} ${theme.pB12} ${theme.mB7} ${theme.borderBottom}`}
        >
          <Link className={theme.text_link} to="/asof/">
            A STATE OF FEELING
          </Link>
          <span>{props.date}</span>
        </div>
        <h1 className={`${styles.title} ${theme.h2_founders} ${theme.mB7}`}>{props.title}</h1>
        <div className={`${styles.entry}  ${theme.mB5}`}>
          <AssetVimeo
            playMode="controls"
            asset={props.fullVideo[0].videoDesktop}
            maxWidth="1440"
            className={`${theme.border}`}
          />
        </div>

        <PortableText
          className={`${styles.copy} ${theme.portableText} ${theme.h3_tiempos} ${theme.mB3}`}
          renderContainerOnSingleChild={true}
          blocks={props._rawEntryCopyPortable}
          serializers={serializers}
        />
      </div>
    </div>
  );
}

export default ProjectSingle;
