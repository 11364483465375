import React from "react";
import { graphql } from "gatsby";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import AsofIntro from "../components/asof-single-intro";
import AsofRows from "../components/asof-single-rows";
import PastNodes from "../components/asof-grid";

export const query = graphql`
  query asofTemplateQuery($id: String!, $prev: String!, $next: String!) {
    entry: sanityAsofEntry(id: { eq: $id }, date: { ne: null }) {
      ...SanityAsofEntryFragment
    }
    prev: sanityAsofEntry(id: { eq: $prev }, date: { ne: null }) {
      ...SanityAsofLinkFragment
    }
    next: sanityAsofEntry(id: { eq: $next }, date: { ne: null }) {
      ...SanityAsofLinkFragment
    }
  }
`;

const asofTemplateQuery = (props) => {
  const { data, errors } = props;
  const { title, description } = data.entry || {};
  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const projectNode = data.entry;
  const nextPrevNodes = [data.prev, data.next];

  return (
    <Layout>
      <SEO title={projectNode.title} description={projectNode.tagline} />
      <Container>
        {projectNode && <AsofIntro {...projectNode} />}
        {projectNode && <AsofRows {...projectNode} />}
        {nextPrevNodes && <PastNodes header={true} nodes={nextPrevNodes} />}
      </Container>
    </Layout>
  );
};

export default asofTemplateQuery;
