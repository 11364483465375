import React from "react";
import Asset from "./asset";
import theme from "../styles/theme.module.scss";

function ProjectRow(props) {
  const row = props.row;
  return (
    <div className={`${theme.gdcont} ${theme.gmcont} ${theme.mB1}`}>
      >
      {row.asset.map((value, key) => {
        const gds = `gds${value.grid?.gridStart ?? 0}`;
        const gde = `gde${value.grid?.gridEnd ?? 13}`;
        const gms = row.gridMobile ? `gms${row.gridMobile.gridStart}` : `gms1`;
        const gme = row.gridMobile ? `gme${row.gridMobile.gridEnd}` : `gme4`;
        const maxWidth = (gde - gds) * 160;

        return (
          <div key={key} className={[theme[gds], theme[gde], theme[gms], theme[gme]].join(" ")}>
            <Asset asset={value.asset[0]} maxWidth={maxWidth} />
            {value.caption && (
              <>
                <p className={theme.mT12}>{value.caption}</p>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default ProjectRow;
